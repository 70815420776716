import React from 'react';
import { useRoutes, Navigate, Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import MainLayout from './layouts/MainLayout';
import HomePage from './pages/Home';

function LayourWrapper({ isMobile }) {
  return (
    <MainLayout />
  )
}

export default function Router() {
  const theme = useTheme();
  
  return useRoutes([
    {
      path: '/',
      element: <LayourWrapper />,
      children: [
        { path: '/:collectionId', element: <HomePage /> },
      ]
    },
  ]);
}
