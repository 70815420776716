import React, { useEffect, useState, useRef } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_PUBLIC_COLLECTION, FOLLOW_COLLECTION, GET_COLLECTION } from '@apis/CollectionApi';
import { GET_PUBLIC_BOOKMARKS } from '@apis/BookmarkApi';
import { getImageUrl, getReadableTime } from '@utils/helper';
import Logo from '@components/Logo';
import { BookmarkIcon, ClockIcon } from '@heroicons/react/24/outline';
import { useLocation, useParams } from 'react-router-dom';
import { WindowScroller, List, InfiniteLoader, AutoSizer, CellMeasurer, CellMeasurerCache, Grid } from 'react-virtualized';
import styled from '@emotion/styled';
import { getEmojiAndName } from '@utils/helper';

const Button = styled.button`
  display: inline-flex;
  display: none;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 9999px;
  border: 1px solid rgba(251, 163, 0, 0.5);
  color: #fBA300;
  font-size: 14px;
  &:hover {
    background-color: rgba(251, 163, 0, 0.08);
  }
`

const PAGE_SIZE = 10;
const defaultHeight = 104;

const cache = new CellMeasurerCache({
  fixedWidth: true,
  // fixedHeight: true,
  defaultHeight,
  // minHeight: 104,
  // keyMapper: () => 1,
});

export default () => {
  const { collectionId } = useParams();

  const infiniteLoaderRef = useRef();
  const [lastLoadingIndex, setLastLoadingIndex] = useState();

  const { loading: publicCollectionLoading, data: publicCollectionData = {}, error } = useQuery(GET_PUBLIC_COLLECTION, {
    variables: {
      id: collectionId,
    }
  });

  const [bookmarkList, setBookmarkList] = useState({
    edges: [],
    pageInfo: {
      afterCursor: null,
      hasNextPage: true,
    },
  });
  const [emoji, setEmoji] = useState();
  const [displayName, setDisplayName] = useState();
  const [followState, setFollowState] = useState(false);

  const [loadPublicBookmarks] = useLazyQuery(GET_PUBLIC_BOOKMARKS);
  const [followCollection] = useMutation(FOLLOW_COLLECTION);
  const [getCollection] = useLazyQuery(GET_COLLECTION);

  if (error) {
    location.href = '/';
    return null;
  }

  const { publicCollection } = publicCollectionData;

  const isRowLoaded = ({ index }) => {
    return !bookmarkList.pageInfo.hasNextPage || index < bookmarkList.edges.length;
  }

  const loadMoreRows = async () => {
    const { data: { publicBookmarks } } = await loadPublicBookmarks({
      fetchPolicy: 'network-only',
      variables: {
        first: PAGE_SIZE,
        collection: collectionId,
        field: 'CREATED_AT',
        direction: 'DESC',
        after: bookmarkList.pageInfo.afterCursor,
      }
    });
    const edges = [...bookmarkList.edges, ...publicBookmarks.edges];
    const { afterCursor } = publicBookmarks.pageInfo;
    const pageInfo = {
      afterCursor,
      hasNextPage: true,
    }

    if ((edges.length > 0 && !publicBookmarks.pageInfo.afterCursor)
      || publicBookmarks.edges.length < PAGE_SIZE
    ) {
      pageInfo.hasNextPage = false;
      if (edges.length > 0) {
        edges.push({ end: true });
      }
    }

    setBookmarkList({ edges, pageInfo });

    // infiniteLoaderRef.current.resetLoadMoreRowsCache(false);
  }

  const getItemCount = () => {
    const count = bookmarkList.edges?.length;
    return bookmarkList.pageInfo.hasNextPage ? count + 1 : count;
  };

  const handleResize = () => {
    cache.clearAll();
  }

  const itemCount = getItemCount();

  useEffect(() => {
    cache.clear(lastLoadingIndex);
    // cache.clearAll();
    // cache.clear(0);
  }, [itemCount]);

  useEffect(() => {
    if (!publicCollection) return;

    if (publicCollection?.name) {
      const title = getEmojiAndName(publicCollection?.name);
      setEmoji(title[0]);
      setDisplayName(title[1]);

      document.title = `${title[1]} – 奇普书签`;
    }

    getCollection({
      variables: {
        id: publicCollection.id,
      },
      onCompleted: (({ collection }) => {
        setFollowState(true);
      }),
      onError: () => {
        setFollowState(false);
      }
    })
  }, [publicCollection]);

  // if (publicCollectionLoading) return null;

  const handleFollow = async () => {
    const currentState = followState;
    try {
      await followCollection({
        variables: {
          collectionId: publicCollection.id,
        },
        onCompleted: () => {
          setFollowState(!currentState);
        },
        onError: ({ message }) => {
          if (message.includes('Access denied!')) {
            window.open('/login');
          }
        }
      })
    } catch(ex) {
      setFollowState(currentState);
    }
  }

  return (
    <div>
      <header className="border-b-gray-100" style={{ paddingLeft: '12px', paddingRight: '12px', paddingTop: '32px', paddingBottom: '24px', borderBottomWidth: '1px' }}>
        <div className="flex justify-between items-center mb-1">
          <h1 className="text-xl font-bold">{emoji && <span style={{ marginRight: '8px' }}>{emoji}</span>}{displayName}</h1>
          <Button
            type="button"
            className="shrink-0 ml-6"
            onClick={handleFollow}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22" className="mr-1">
              <g fill="none" fill-rule="evenodd">
                <g fill="currentColor">
                  <path fill-rule="nonzero" d="M16.46 10.625c.364.713.57 1.52.57 2.374a15.507 15.507 0 0 1-6.023 1.209c-2.136 0-4.172-.43-6.024-1.21a5.232 5.232 0 0 1 .57-2.373 13.09 13.09 0 0 0 5.454 1.181 13.09 13.09 0 0 0 5.453-1.18zM16.616 15.055a5.252 5.252 0 0 1-4.835 3.205h-1.549a5.254 5.254 0 0 1-4.834-3.205c1.7.804 3.602 1.254 5.609 1.254s3.91-.45 5.61-1.254z" />
                  <path d="M10.48 18.936c.291.29.762.29 1.054 0l.979-.976H9.5l.98.976z" />
                  <path fill-rule="nonzero" d="M6.595 9.22c1.398.414 2.879.635 4.412.635 1.532 0 3.013-.221 4.411-.634a5.228 5.228 0 0 0-3.637-1.467h-1.549a5.23 5.23 0 0 0-3.637 1.467z" />
                  <g>
                    <path d="M11.158 7.754C11.158 4.024 14.19 1 17.934 1c0 3.73-3.034 6.754-6.776 6.754zM4.08 1c3.742 0 6.776 3.024 6.776 6.754C7.114 7.754 4.08 4.73 4.08 1z" />
                  </g>
                </g>
                <path d="M0 0h22v22H0z" />
              </g>
            </svg>
            {
              followState ? '已关注' : '关注'
            }
          </Button>
        </div>
        <div className="text-gray-500 text-base">{publicCollection?.description}</div>
      </header>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={itemCount}
        ref={infiniteLoaderRef}
      >
        {
          ({ onRowsRendered, registerChild }) => (
            <WindowScroller>
              {({ height, isScrolling, scrollTop }) => (
                <AutoSizer disableHeight onResize={handleResize}>
                  {({ width }) => (
                    <List
                      ref={registerChild}
                      width={width}
                      height={height}
                      rowCount={itemCount}
                      rowHeight={cache.rowHeight}
                      onRowsRendered={onRowsRendered}
                      deferredMeasurementCache={cache}
                      autoHeight
                      scrollTop={scrollTop}
                      rowRenderer={({ index, key, style, parent }) => (
                        <CellMeasurer
                          key={key}
                          columnIndex={0}
                          parent={parent}
                          cache={cache}
                          rowIndex={index}
                        >
                          {({ measure, registerChild }) => {
                            const bookmark = bookmarkList.edges[index];
                            if (!bookmark) {
                              setLastLoadingIndex(index);
                            }
                            return (
                              <div key={index} ref={registerChild} style={style}>
                                {
                                  !bookmark ? (
                                    <div role="status" className="flex items-center justify-center" style={{ padding: '48px' }}>
                                      <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin" style={{ fill: 'rgb(251, 163, 0)' }} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                      </svg>
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  ) : (
                                    bookmark.end ? (
                                      <div
                                        style={{ paddingTop: '24px', paddingBottom: '24px', fontSize: '14px' }}
                                        className="text-center text-gray-400"
                                        onDoubleClick={() => {
                                          window.scrollTo(0, 0);
                                        }}
                                      >
                                        共{bookmarkList.edges.length}条
                                      </div>
                                    ) : (
                                      <a
                                        target="_blank"
                                        href={bookmark.node.url}
                                        style={{ display: 'flex', paddingTop: 24, paddingBottom: 24, paddingLeft: 12, paddingRight: 12 }}
                                        className="hover:bg-gray-50 border-b-gray-100 border-b"
                                      >
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
                                          <div style={{ fontSize: 16, display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden', fontWeight: 'bold', marginBottom: 6 }}>{bookmark.node.title}</div>
                                          <div style={{ fontSize: 14, display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden', marginBottom: 8 }}>{bookmark.node.description}</div>
                                          <div style={{ fontSize: 14, display: 'flex', alignItems: 'center' }} className="text-gray-400">
                                            <span>{(new URL(bookmark.node.url)).host}</span>
                                            <span className="" style={{ fontWeight: 200, opacity: .8, marginLeft: 6, marginRight: 6 }}>·</span>
                                            <span>{getReadableTime(bookmark.node.createdAt)}</span>
                                          </div>
                                        </div>
                                        {
                                          bookmark.node.image ? (
                                            <div style={{ width: 128, height: 72, marginLeft: 24, overflow: 'hidden' }}>
                                              <img src={`${getImageUrl(bookmark.node.image, bookmark.node.url, 'cover')}`} style={{ objectFit: 'cover' }} />
                                            </div>
                                          ) : null
                                        }
                                      </a>
                                    )
                                  )
                                }
                              </div>
                            )
                          }}
                        </CellMeasurer>
                      )}
                    />
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          )
        }
      </InfiniteLoader>
      {
        // bookmarkList.edges.length > 0 ? (
        <footer className="flex flex-col items-center justify-center mt-16">
          <a href="/" target="_blank" className="flex flex-col items-center justify-center">
            <Logo viewBox="0 0 48 48" sx={{ fontSize: '26px' }} />
            <span className="text-sm mt-2.5 text-gray-500">你喜爱的，汇集一处</span>
          </a>
          <div className="flex items-center text-sm text-gray-500 mt-8">
            <div>©2024 Kiipu</div>
            <a target="_blank" href="/explore" className="hover:underline ml-8">发现</a>
            <i className="inline-block border-r border-r-gray-100 h-3 pl-4 mr-4"></i>
            <button id="mega-menu-dropdown-button" data-dropdown-toggle="mega-menu-dropdown" className="hover:underline">
              联系我们
            </button>
          </div>
          <div id="mega-menu-dropdown" className="absolute z-100 grid hidden w-auto grid-cols-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md">
            <div className="p-4 pb-0 text-gray-900 md:pb-4 dark:text-white">
              <ul className="space-y-4" aria-labelledby="mega-menu-dropdown-button">
                <li>
                  <a target="_blank" href="https://twitter.com/KiipuApp" className="text-gray-500 hover:underline">
                    X
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://discord.gg/u8PKkUYVce" className="text-gray-500 hover:underline">
                    Discord
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://weibo.com/u/7858148641" className="text-gray-500 hover:underline">
                    微博
                  </a>
                </li>
              </ul>
            </div>
            <div class="p-4">
              <ul class="space-y-4">
                <li>
                  <a target="_blank" href="https://v.douyin.com/idqEerUX/" className="text-gray-500 hover:underline">
                    抖音
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.xiaohongshu.com/user/profile/64c72e3a000000000e026e37" className="text-gray-500 hover:underline">
                    小红书
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://okjk.co/jRzy9x" className="text-gray-500 hover:underline">
                    即刻
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
        // ) : null
      }
    </div>
  )
}
