import React, { useContext, useEffect } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { styled, useTheme, alpha } from '@mui/material/styles';

export default () => {
  return (
    <main className="max-w-screen-lg	mx-auto pb-12">
      <div
        className="px-12
          max-xl:px-8

          max-lg:px-6

          max-md:px-4

          max-[414px]:px-2
        "
      >
        <Outlet />
      </div>
    </main>
  )
}

// const RootStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   minHeight: '100%',
//   overflow: 'hidden',
//   backgroundColor: theme.palette.background.paper,
// }));

// const MainStyle = styled('main')(({ theme, open }) => ({
//   width: '900px',
//   margin: '0 auto',
// }));


// export default () => {
//   const theme = useTheme();

//   return (
//     <RootStyle>
//       <MainStyle theme={theme} open={true}>
//         <Outlet />
//       </MainStyle>
//     </RootStyle>
//   )
// }