import { gql } from "@apollo/client";

// 获取书签列表
export const GET_BOOKMARKS = gql`
  query Bookmarks($first: Float, $after: String, $collection: String, $keywords: String, $field: BookmarkSortByField!, $direction: SortByDirection!) {
    bookmarks(first: $first, orderBy: { field: $field, direction: $direction }, after: $after, collection: $collection, keywords: $keywords) {
      edges {
        node {
          id
          title
          url
          faviconUrl
          description
          image
          type
          extra
          card
          isStarred
          collection {
            id
            name
          }
          note
          createdAt
          from
          parser
          creator {
            nickname
          }
        }
      }
      pageInfo {
        afterCursor
      }
    }
  }
`;

// 删除书签
export const DELETE_BOOKMARK = gql`
  mutation($id: String!) {
    bookmarkDelete(id: $id) {
      bookmark {
        id
        title
      }
    }
  }
`;

// 恢复书签
export const RECORVERY_BOOKMARK = gql`
  mutation($id: String!) {
    bookmarkRecorvery(id: $id) {
      bookmark {
        id
        title
        collection {
          id
          name
        }
      }
    }
  }
`;

// 永久删除书签
export const FOREVER_DELETE_BOOKMARK = gql`
  mutation($id: String!) {
    bookmarkForeverDelete(id: $id) {
      bookmark {
        id
        title
      }
    }
  }
`;

// 移动书签
export const MOVE_BOOKMARK = gql`
  mutation($id: String!, $collectionId: String) {
    bookmarkMove(id: $id, collectionId: $collectionId) {
      bookmark {
        id
        collection {
          id
          name
        }
      }
    }
  }
`;

// 添加书签
export const ADD_BOOKMARK = gql`
  mutation($input: BookmarkCreateInput!) {
    bookmarkCreate(input: $input) {
      bookmark {
        id
        url
        title
        description
        faviconUrl
        image
        extra
        collectionId
        from
        card
        parser
        text
      }
    }
  }
`;

// 从元信息添加书签
export const ADD_BOOKMARK_FROM_META = gql`
  mutation($metaId: String!, $input: BookmarkCreateFromMetaInput!) {
    bookmarkCreateFromMeta(metaId: $metaId, input: $input) {
      bookmark {
        id
        url
        title
        description
        faviconUrl
        image
        extra
        collectionId
        card
        parser
        text
        from
      }
    }
  }
`;

// 获取已加星标列表
export const GET_STARRED_BOOKMARKS = gql`
  query StarredBookmarks($first: Float, $after: String, $keywords: String, $field: BookmarkSortByField!, $direction: SortByDirection!) {
    starredBookmarks(first: $first, orderBy: { field: $field, direction: $direction }, after: $after, keywords: $keywords) {
      edges {
        node {
          bookmark {
            id
            title
            url
            faviconUrl
            description
            image
            type
            extra
            card
            collection {
              id
              name
            }
            createdAt
          }
        }
      }
      pageInfo {
        afterCursor
      }
    }
  }
`;

// 获取所有书签（前100条）
export const GET_BOOKMARK_ALL = gql`
  query BookmarkAll {
    bookmarkAll {
      id
      title
      url
      faviconUrl
      description
      image
      type
      extra
      card
      isStarred
      collection {
        id
        name
      }
      note
      createdAt
      from
      parser
      creator {
        nickname
      }
    }
  }
`;

// 获取公开收藏夹书签列表
export const GET_PUBLIC_BOOKMARKS = gql`
  query PublicBookmarks($first: Float, $after: String, $collection: String, $keywords: String, $field: BookmarkSortByField!, $direction: SortByDirection!) {
    publicBookmarks(first: $first, orderBy: { field: $field, direction: $direction }, after: $after, collection: $collection, keywords: $keywords) {
      edges {
        node {
          id
          title
          url
          faviconUrl
          description
          image
          type
          extra
          card
          collection {
            id
            name
          }
          createdAt
          from
          parser
          creator {
            nickname
          }
        }
      }
      pageInfo {
        afterCursor
      }
    }
  }
`;

export const UPDATE_BOOKMARK = gql`
  mutation($id: String!, $input: BookmarkUpdateInput!) {
    bookmarkUpdate(id: $id, input: $input) {
      bookmark {
        id
        url
        title
        description
        faviconUrl
        image
        type
        extra
        card
        parser
        from
      }
    }
  }
`;

// 云端解析更新书签
export const BOOKMARK_CLOUSE_PARSER_ASYNC = gql`
  mutation($input: BookmarkCloudParserAsyncInput!) {
    bookmarkCloudParserAsync(input: $input) {
      bookmarkParserRecordId
    }
  }
`;

// 云端解析更新书签
export const UPDATE_BOOKMARK_CLOUD_PARSER = gql`
  mutation($input: BookmarkCloudParserUpdateInput!) {
    bookmarkCloudParserUpdate(input: $input) {
      bookmark {
        id
        url
        title
        description
        faviconUrl
        image
        extra
        collectionId
        from
        card
        parser
        text
      }
    }
  }
`;

// 快速创建书签
export const QUICK_CREATE_BOOKMARK = gql`
  mutation($input: BookmarkQuickCreateInput!) {
    bookmarkQuickCreate(input: $input) {
      bookmark {
        id
        url
        title
        description
        faviconUrl
        image
        extra
        collectionId
        from
        card
        parser
        text
      }
    }
  }
`;