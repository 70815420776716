import { gql } from "@apollo/client";

// 创建收藏夹
export const ADD_COLLECTION = gql`
  mutation CollectionCreate($name: String!, $parentId: String) {
    collectionCreate(input: { name: $name, parentId: $parentId }) {
      collection {
        id,
        name,
        parent {
          id
        }
      }
    }
  }
`;

// 修改收藏夹
export const UPDATE_COLLECTION = gql`
  mutation CollectionUpdate($id: String!, $input: CollectionUpdateInput!) {
    collectionUpdate(id: $id, input: $input) {
      collection {
        id,
        name,
      }
    }
  }
`;

// 获取收藏夹列表
export const GET_COLLECTIONS = gql`
  query {
    collections {
      id
      name
      parentId
      order
      isPublic
      publish
      count
      role
    }
    pinnedCollections {
      collection {
        id
        name
        count
        role
      }
    }
  }
`;

// 获取单个收藏夹
export const GET_COLLECTION = gql`
  query Collection($id: String!) {
    collection(id: $id) {
      id
      name
      isPublic
      publish
      sortByDirection
      sortByField
      view
      role
    }
  }
`;

// 获取公开收藏夹
export const GET_PUBLIC_COLLECTION= gql`
  query PublicCollection($id: String!) {
    publicCollection(id: $id) {
      id
      name
      description
    }
  }
`;

// 删除收藏夹
export const DELETE_COLLECTION = gql`
  mutation CollectionDelete($collectionDeleteId: String!) {
    collectionDelete(id: $collectionDeleteId) {
      deletedIds
    }
  }
`;

// 移动收藏夹
export const MOVE_COLLECTION = gql`
  mutation($id: String!, $input: MoveCollectionInput!) {
    collectionMove(id: $id, input: $input) {
      collection {
        id
        name
      }
    }
  }
`;

// 设置收藏夹公开访问
export const SET_COLLECTION_PUBLISH = gql`
  mutation CollectionPublish($id: String!, $publish: Boolean!) {
    collectionPublish(id: $id, publish: $publish) {
      collection {
        id,
        name,
        publish,
      }
    }
  }
`;

// 设置收藏夹排序
export const UPDATE_SORT_BY_COLLECTION = gql`
  mutation CollectionSortByUpdate($id: String!, $input: CollectionSortByUpdateInput!) {
    collectionSortByUpdate(id: $id, input: $input) {
      collection {
        name,
        sortByField,
        sortByDirection,
      }
    }
  }
`;

// 设置收藏夹视图
export const UPDATE_VIEW_COLLECTION = gql`
  mutation CollectionViewUpdate($id: String!, $view: View!) {
    collectionViewUpdate(id: $id, view: $view) {
      collection {
        name,
        view,
      }
    }
  }
`;

// 分享收藏夹
export const SHARE_COLLECTION = gql`
  mutation CollectionShare($input: CollectionShareInput!) {
    collectionShare(input: $input) {
      collection {
        id,
        name,
        isPublic,
      }
    }
  }
`;

// 获取收藏夹分享列表
export const GET_COLLECTION_SHARE_LIST= gql`
  query Collection($id: String!) {
    collection(id: $id) {
      members {
        email,
        theme,
        nickname,
        avatar,
        role
      }
    }
  }
`;

// 移除分享成员
export const REMOVE_COLLECTION_MEMBER = gql`
  mutation CollectionMemberRemove($input: CollectionMemberRemoveInput!) {
    collectionMemberRemove(input: $input) {
      members {
        email,
        theme,
        nickname,
        avatar,
        role
      }
    }
  }
`;

// 更新分享成员
export const UPDATE_COLLECTION_MEMBER = gql`
  mutation CollectionMemberUpdate($input: CollectionMemberUpdateInput!) {
    collectionMemberUpdate(input: $input) {
      members {
        email,
        theme,
        nickname,
        avatar,
        role
      }
    }
  }
`;

// 关注收藏夹
export const FOLLOW_COLLECTION = gql`
  mutation CollectioFollow($collectionId: String!) {
    collectionFollow(collectionId: $collectionId) {
      collection {
        id,
        name,
        isPublic,
      }
    }
  }
`;
