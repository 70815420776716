import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, GlobalStyles } from '@mui/material';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  from,
  useQuery,
  gql
} from "@apollo/client";
import { onError } from "apollo-link-error";
import { setContext } from '@apollo/client/link/context';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import StandaloneDivider from '@components/StandaloneDivider';
import Router from './routes';
// import ThemeConfig from './theme';
import themes from '../../themes';

const customGlobalStyles = <GlobalStyles styles={{
  'html': {
    fontSize: '16px',
  },
  'body': {
    backgroundColor: '#fff',
  },
  'body > div:first-child': {
    height: '100%',
  }
}} />;

const getGraphqlUri = () => {
  let uri;
  if (process.env.API_HOST) {
    uri = process.env.API_HOST;
  } else {
    if (location.host === 'kiipu.com' || location.host === 'beta.kiipu.com') {
      uri = 'api.kiipu.com';
    } else {
      uri = 'dev-api.kiipu.com:3001';
    }
  }
  return `//${uri}/graphql`;
}

const httpLink = createHttpLink({
  uri: getGraphqlUri(),
  credentials: 'include',
  useGETForQueries: true,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('[graphQLErrors]', graphQLErrors);
  // if (graphQLErrors) {
  //   for (let err of graphQLErrors) {
  //     switch (err.extensions.code) {
  //       case 'UNAUTHENTICATED':
  //         localStorage.removeItem('token');
  //         location.replace('/login');
  //         return;
  //     }
  //   }
  // }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      if (message.includes('Access denied!')) {
        // localStorage.removeItem('token');
        // location.replace('/login');
      } else {
        // console.log("dispatch");
        // snackbarStore.dispatch.snackbar.handleOpen(message);
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([
    errorLink,
    authMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  queryDeduplication: false,
});

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes()}>
        <CssBaseline />
        { customGlobalStyles }
        <StandaloneDivider />
        <ApolloProvider client={client}>
          <DndProvider backend={HTML5Backend}>
            <Router />
          </DndProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
